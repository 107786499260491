<template>
  <div class="login">
    <div class="right_r">
      <div class="right_r_logo">
        <a href="/">
          <img src="../assets/a.png" width="125">
        </a>
      </div>
      <span style="line-height: 40px;margin: 0 20px;">{{$configs('version')}}</span>
    </div>
    <div class="login1">
      <div class="login_lt">
        <img src="../assets/b.png" width="123" height="39">
      </div>
      <div class="login_rt">
        <span>您还未登陆，请先登录！</span>
        <Form ref="loginForm" :model="userItem" :rules="ruleLogin">
          <div class="padding-top-20"></div>
          <Form-item prop="username">
            <Input type="text" v-model="userItem.username" @on-enter="loginSubmit" placeholder="用户名">
              <Icon type="ios-person-outline" slot="prepend"></Icon>
            </Input>
          </Form-item>
          <Form-item prop="password">
            <Input type="password" v-model="userItem.password" @on-enter="loginSubmit" placeholder="登陆密码">
              <Icon type="ios-lock-outline" slot="prepend"></Icon>
            </Input>
          </Form-item>
          <Form-item prop="remember">
            <Checkbox v-model="userItem.remember">记住用户名</Checkbox>
          </Form-item>
          <!--<div class="padding-top-20"></div>-->
          <Button type="success" long :disabled="!submitEnabled" @click="loginSubmit">登录</Button>
        </Form>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'login',
  components: {
  },
  data () {
    return {
      userItem: {
        username: '',
        password: '',
        Permission: 1,
        // mask： 以何种身份登陆 -1=不限制
        mask: -1,
        remember: false
      },
      ruleLogin: {
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          { type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      },
      apiItem: {
        apiHost: '',
        apiService: 'zztAuth',
        apiAction: 'Login',
        apiQuery: {}
      },
      submitEnabled: true
    }
  },
  beforeMount () {
    // this.$refs.topProgress.start()
  },
  mounted () {
    // this.$refs.topProgress.done()
    document.querySelector('body').className += ' login-page'
  },
  methods: {
    loginSubmit () {
      this.submitEnabled = false
      this.$refs['loginForm'].validate(async (valid) => {
        if (valid) {
          this.$Spin.show()
          console.log(this.$Api.Auth)
          await this.$Api.Auth(this).login(this.userItem).then(() => {
            this.submitEnabled = false
            this.$Spin.hide()
            this.getUserInfo()
          })
          .catch(err => {
            this.submitEnabled = true
            this.$Spin.hide()
            this.$Notice.error({
              title: '登录失败!',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
        }
      })
    },
    getToken () {
      return this.$store.getters.token
    },
    async getUserInfo () {
      this.$Spin.show()
      await this.$Api.Auth(this).myInfo().then((payload) => {
        this.$Spin.hide()
        this.$store.dispatch('setUserStore', payload.User)
        this.$Notice.success({
          title: '登录成功',
          desc: '欢迎回来！' + this.$store.getters.name
        })
        this.$router.push({ path: '/home' })
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取个人信息失败!',
          desc: err.message
        })
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url(../assets/beijing.jpg) no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.right_r {
  position: absolute;
  top: 40px;
  right: 70px;
}

.right_r_logo {
  float: left;
}

.right_r_ft {
  float: left;
  background-image: url(../assets/zhuce.png);
  margin-left: 30px;
}

.right_r_ft a {
  display: block;
  width: 74px;
  height: 30px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  line-height: 27px;
}

.login1 {
  width: 580px;
  height: 350px;
  margin: auto;
  margin-top: 12%;
  border-radius: 12px;
  background: linear-gradient(to bottom, #00aa8f, #47b160);
  box-shadow: 1px 1px 10px rgba(0,0,0,.2);
}

.login_lt {
  float: left;
  width: 258px;
  height: 350px;
}

.login_lt img {
  position: relative;
  top: 136px;
  left: 68px;
}

.login_rt {
  float: left;
  width: 322px;
  height: 350px;
  background-color: #FFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.login_rt span {
  text-align: right;
  margin: 18px 0 18px 0;
  font-size: 14px;
  color: #95A3AA;
}
.login_rt form {
  flex: 1;
  position: relative;
}
/* .login_rt form button { */
  /*position: absolute;*/
  /*bottom: 0;*/
/* } */
</style>
